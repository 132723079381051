import { Card } from "@mui/material";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import Header from "../Header/Header";
import "./Dashboard.css"



function Dashboard() {

  const [user, loading, error] = useAuthState(auth);

  const navigate = useNavigate();


  React.useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    console.log(user)
  }, [user, loading]);

  return (
    <div className="dashboard">
       <Header />
       <div className="page_title_bar"> 
           <div className="page_title"> 
           Welcome Declan. 
           </div>
       </div>

       <div className="dashboard_master_div"> 
         <div className="dashboard_secondary_div"> 
           <Card className="dashboard_temp_card">
           <div className="dashboard_master_div"> 
         <div className="dashboard_secondary_div2"> 
         Dashboard Coming Soon...
         </div>
       </div>

           </Card>
         </div>
       </div>
     </div>
  );
}
export default Dashboard;