import { Add } from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  Fab,
  ListItem,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import React from "react";
import { db } from "../../firebase";
import ArticleAdd from "../Article Add/ArticleAdd";
import "./CatergoryEdit.css";

function CatergoryEdit(props) {
  const propDat = props.catergory;
  const { filter } = props;

  const [state, setState] = React.useState({
    title: "",
    description: "",
    articles: [],
  });

  React.useEffect(async () => {
    setState((state) => ({
      ...state,
      title: propDat.title && propDat.title,
      description: propDat.description && propDat.description,
    }));
  }, [propDat]);

  function handleTextChange(e, field) {
    setState((state) => ({
      ...state,
      [field]: e.target.value,
    }));
  }

  /* Fetch Results */
  React.useEffect(async () => {
    const arr = [];
    const querySnapshot = await getDocs(
      collection(db, `${filter}-articles`, propDat.id, "articles")
    );
    await querySnapshot.forEach((doc) => {
      arr.push({ ...doc.data(), id: doc.id });
    });
    setState((state) => ({
      ...state,
      articles: arr,
    }));
  }, [props]);

  /* Handle Database Update */
  async function handleDatabaseUpdate() {
    const docPath = doc(
      db,
      `${filter}-articles`,
      propDat.id,
    );
    await updateDoc(docPath, {
      title: state.title,
      description: state.description,
    })
      .then((r) => {
        setState((state) => ({
          ...state,
          successMessage: "Successfully Updated Article Category",
          successOpen: true,
        }));
      })
      .catch((r) => {
        setState((state) => ({
          ...state,
          errorMessage: "Failed To Update Category",
          errorOpen: true,
        }));
      });
  }

  /* Function to handle Deleting A Catergory */



    /* Handle Snackbar Close */
    const [vertical, setVertical] = React.useState("bottom");
    const [horizontal, setHorizontal] = React.useState("right");
    const handleErrorClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setState((state) => ({
        ...state,
        errorOpen: false,
        errorMessage: "",
        successMessage: "",
        successOpen: false,
      }));
    };

  return (
    <>
      <Card className="CatergoryEdit_card">
        <div className="CatergoryEdit_card_header_bar">
          <div className="CatergoryEdit_card_header_bar_text">
            Category Edit
          </div>
        </div>
        <div className="CatergoryEdit_card_textFields">
          <TextField
            label="Title"
            size="small"
            fullWidth
            style={{ marginTop: "10px" }}
            value={state.title}
            onChange={(e) => {
              handleTextChange(e, "title");
            }}
            onBlur={() => {
              handleDatabaseUpdate();
            }}

          />
          <TextField
            label="Description"
            size="small"
            fullWidth
            style={{ marginTop: "10px" }}
            multiline
            rows={8}
            value={state.description}
            onChange={(e) => {
              handleTextChange(e, "description");
            }}
            onBlur={() => {
              handleDatabaseUpdate();
            }}
          />
        </div>
        <div>
          <div style={{
              display:'flex', 
              flexDirection:'row', 
              justifyContent:'flex-end', 
              marginRight:'10px'
          }}>
            <ArticleAdd catergory={propDat} filter={filter} />
          </div>
        </div>
      </Card>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{ maxWidth: "30%" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{ maxWidth: "30%" }}
      >
        <Alert onClose={handleErrorClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CatergoryEdit;
