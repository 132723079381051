import {
  Add,
  Description,
  DescriptionOutlined,
  ExpandLess,
  ExpandMore,
  More,
} from "@mui/icons-material";
import {
  Button,
  Card,
  Collapse,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { collection, getDocs, onSnapshot, query } from "firebase/firestore";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import Header from "../Header/Header";
import ArticleEdit from "./Article Edit/ArticleEdit";
import CatergoryEdit from "./Catergory Edit/CatergoryEdit";
import GroupVArticleAdd from "./Group Add/GroupVArticleAdd";
import "./SupportPage.css";

function SupportPage() {
  const [user, loading, error] = useAuthState(auth);

  const navigate = useNavigate();


  React.useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    console.log(user)
  }, [user, loading]);

  const [state, setState] = React.useState({
    selectedFilter: "tradesman",
    results: [],
    selectedCatergory: {},
    openArticles: [],
    selectedArticle: {}, 
    hiddenCat: {}
  });

  const selectedStyle = {
    color: "white",
    backgroundColor: "rgb(18, 100, 100)",
    marginLeft: "5px",
  };
  const unselected = {
    color: "rgb(18, 100, 100)",
    marginLeft: "5px",
  };

  function handleFilterSelect(filter) {
    setState((state) => ({
      ...state,
      selectedFilter: filter,
    }));
    handleCatergoryFetch(filter);
  }

  React.useEffect(async () => {
    handleCatergoryFetch("tradesman");
  }, []);

  async function handleCatergoryFetch(filter) {
    const arr = [];
    const arr2 = {};
    const querySnapshot = await getDocs(collection(db, `${filter}-articles`));
    await querySnapshot.forEach((doc) => {
      arr.push({ ...doc.data(), id: doc.id });
    });
    setState((state) => ({
      ...state,
      results: arr,
    }));
    handleOpenObj(arr);
  }

  function handleOpenObj(propArr) {
    const arr = [...propArr];
    const obj = arr.reduce((a, v, i) => ({ ...a, [i]: false }), {});
    setOpen(obj);
  }

  function handleCatergorySelect(item) {
    setState((state) => ({
      ...state,
      selectedCatergory: item,
      hiddenCat: item,
      selectedArticle: {}
    }));
  }

  const [open, setOpen] = React.useState({});

  const handleClick = (item, index) => {
    const answer = !open[index];
    if (open.last_open === index) {
      setOpen((open) => ({
        ...open,
        [index]: answer,
      }));
    } else {
      setOpen((open) => ({
        ...open,
        [index]: answer,
        last_open: index,
        [open.last_open]: false,
      }));
      handleArticleFetch(item);
    }
  };

  async function handleArticleFetch(item) {
    const q = await query(
      collection(db, `${state.selectedFilter}-articles`, item.id, "articles")
    );

    onSnapshot(q, async (querySnapshot) => {
      const arr = [];
      await querySnapshot.forEach((doc) => {
        arr.push({ ...doc.data(), id: doc.id });
      });
      setState((state) => ({
        ...state,
        openArticles: arr,
      }));
    });
  }

  return (
    <>
      <Header />
      <div className="page_title_bar">
        <div className="page_title">Support Documentation.</div>
      </div>
      <div className="SupportPage_content">
        <Card className="SupportPage_list_card">
          <div className="SupportPage_list_buttons">
            <Button
              style={
                state.selectedFilter === "tradesman"
                  ? selectedStyle
                  : unselected
              }
              onClick={() => {
                handleFilterSelect("tradesman");
              }}
            >
              Tradesman
            </Button>
            <Button
              style={
                state.selectedFilter === "investor" ? selectedStyle : unselected
              }
              onClick={() => {
                handleFilterSelect("investor");
              }}
            >
              Investor
            </Button>
            <Button
              style={
                state.selectedFilter === "tenant" ? selectedStyle : unselected
              }
              onClick={() => {
                handleFilterSelect("tenant");
              }}
            >
              Tenant
            </Button>
            <Button
              style={
                state.selectedFilter === "audit" ? selectedStyle : unselected
              }
              onClick={() => {
                handleFilterSelect("audit");
              }}
            >
              Audit
            </Button>
          </div>
          <div className="SupportPage_list">
            {state.results &&
              state.results.map((item, index) => (
                <>
                  <ListItem
                    button
                    dense
                    key={item.title}
                    onClick={() => {
                      handleCatergorySelect(item);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography style={{ fontWeight: "500" }}>
                          {item.title}
                        </Typography>
                      }
                    />
                    <ListItemIcon>
                      <IconButton
                        onClick={() => {
                          handleClick(item, index);
                        }}
                        style={{ color: "rgb(18, 100, 100)" }}
                      >
                        {open[index] ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                  <Collapse in={open[index]} timeout="auto" unmountOnExit>
                    <div className="SupportPageOpenList">
                      {state.openArticles &&
                        state.openArticles.map((article) => {
                          return (
                            <ListItem
                              button
                              key={article.id}
                               onClick={() => {
                                setState((state) => ({
                                  ...state, 
                                  selectedArticle: article, 
                                  selectedCatergory: {}
                                }))
                              }} 
                            >
                              <ListItemIcon>
                                <DescriptionOutlined
                                  style={{ color: "rgb(18, 100, 100)" }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={article.title && article.title}
                              />
                            </ListItem>
                          );
                        })}
                    </div>
                  </Collapse>
                </>
              ))}
          </div>
          <div className="SupportPage_fab_div">
            <GroupVArticleAdd area={state.selectedFilter} />
          </div>
        </Card>

        {state.selectedCatergory.title ? (
          <CatergoryEdit
            catergory={state.selectedCatergory}
            filter={state.selectedFilter}
          />
        ) : (
          <div />
        )}

{state.selectedArticle.title ? (
          <ArticleEdit
            catergory={state.hiddenCat}
            filter={state.selectedFilter}
            article={state.selectedArticle}
          />
        ) : (
          <div />
        )}
      </div>
    </>
  );
}

export default SupportPage;
