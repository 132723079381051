import { Alert, Button, Card, Snackbar, TextField } from "@mui/material";
import React from "react";
import "./Login.css";
import logo from "../Images/tenacklogo.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";

function Login() {
  /* Hooks */
  const [user, loading, error] = useAuthState(auth);

  const navigate = useNavigate();
  const [state, setState] = React.useState({
    errorOpen: false,
    errorMessage: "",
    email: "",
    password: "",
  });

  const [vertical, setVertical] = React.useState("bottom");
  const [horizontal, setHorizontal] = React.useState("right");

  /* handle text field value changes */
  function handleChange(e, field) {
    setState((state) => ({
      ...state,
      [field]: e.target.value,
    }));
  }

  React.useEffect(() => {
    if (loading) {
      setState((state) => ({
        ...state,
        loading: true,
      }));
      return;
    } else {
      setState((state) => ({
        ...state,
        loading: false,
      }));
    }
    if (error) {
      setState((state) => ({
        ...state,
       errorOpen: true,
        errorMessage:
          "Error This Login is for Tenack Employees Only. Contact Supervisor for Assistance",
      })); 
    }
  }, [user, loading, error]);

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      errorOpen: false,
      errorMessage: "",
    }));
  };

  return (
    <>
      <div className="loginScreen">
        <div className="login_subDiv">
          <Card className="login_card">
            <div className="login_card_img_div">
              <img src={logo} alt="tenack logo" className="login_logo_img" />
            </div>
            <div className="login_card_subtitle"> 
                <div className="login_card_subtitle_text">Employee Portal</div>
            </div>
            <div className="login_card_textFields">
              <TextField
                label={"email"}
                dense
                fullWidth
                placeholder="placeholder@tenack.com"
                onChange={(e) => {
                  handleChange(e, "email");
                }}
                value={state.email}
              />
              <TextField
                label={"password"}
                type="password"
                dense
                fullWidth
                onChange={(e) => {
                  handleChange(e, "password");
                }}
                value={state.password}
              />
              <div className="login_card_submit_button_div">
                <Button
                  style={{
                    backgroundColor: "rgb(18, 100, 100)",
                    color: "white",
                  }}
                  onClick={() => {
                    signInWithEmailAndPassword(
                      auth,
                      state.email,
                      state.password
                    ).then(() => {
                        navigate("/support_documents")
                    }).catch((r) => {
                        setState((state) => ({
                            ...state,
                           errorOpen: true,
                            errorMessage:
                              "Error This Login is for Tenack Employees Only. Contact Supervisor for Assistance",
                          })); 
                    });
                  }}
                >
                  Log In
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{maxWidth:'30%'}}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Login;
