import { CopyAll, LegendToggleSharp } from "@mui/icons-material";
import {
  Alert,
  Badge,
  Button,
  Card,
  Fab,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";
import { Quill } from "quill";
import React from "react";
import ReactQuill from "react-quill";
import "./ArticleEdit.css";
import "react-quill/dist/quill.snow.css";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { convert } from "html-to-text";
import htmlToFormattedText from "html-to-formatted-text";


function ArticleEdit(props) {
  const { catergory, filter, article } = props;
  const [value, setValue] = React.useState("");
  const [state, setState] = React.useState({
    errorOpen: false,
    successOpen: false,
    successMessage: "",
    errorMessage: "",
    title: "",
    description: "",
    published: false
  });

  function handleTextChange(e, field) {
    setState((state) => ({
      ...state,
      [field]: e.target.value,
    }));
  }

  React.useEffect(() => {
    setState((state) => ({
      ...state,
      title: article.title && article.title,
      description: article.description && article.description,
      published: article.published && article.published,
    }));
    setValue(article.text_value ? article.text_value : "");
  }, [article.id]);

  /* Handle Database Update */
  async function handleDatabaseUpdate() {
    console.log(props);

    const docPath = doc(
      db,
      `${filter}-articles`,
      catergory.id,
      "articles",
      article.id
    );
    await updateDoc(docPath, {
      title: state.title,
      description: state.description,
      text_value: value,
      published: state.published
    })
      .then((r) => {
        setState((state) => ({
          ...state,
          successMessage: "Successfully Updated Article",
          successOpen: true,
        }));
      })
      .catch((r) => {
        setState((state) => ({
          ...state,
          errorMessage: "Failed To Update Article",
          errorOpen: true,
        }));
      });
  }

  /* Handle Snackbar Close */
  const [vertical, setVertical] = React.useState("bottom");
  const [horizontal, setHorizontal] = React.useState("right");
  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      errorOpen: false,
      errorMessage: "",
      successMessage: "",
      successOpen: false,
    }));
  };

  async function handleArticleDelete() {
    const docPath = doc(
      db,
      `${filter}-articles`,
      catergory.id,
      "articles",
      article.id
    );
    await deleteDoc(docPath, {
      title: state.title,
      description: state.description,
      text_value: value,
    });
  }

  function handleContentCopy() {


    const content = `${<h1>state.titl</h1>} ${state.description}`
    const text = htmlToFormattedText(content)

    const title =  htmlToFormattedText(state.title)
    const desc =  htmlToFormattedText(state.description)
    const msg = htmlToFormattedText(value)

    const full = `${title} ${desc} ${msg}`


    navigator.clipboard.writeText(full)

   
        setState((state) => ({
          ...state,
          errorMessage: "",
          errorOpen: false,
          successMessage: "Successfully copied content to clipboard...",
          successOpen: true,
        }));

  }

  function handlePublishedChange () {
    setState((state) => ({
      ...state,
      published: !state.published
    }))
  }

  return (
    <>
      <Card className="ArticleEdit_card">
        <div className="CatergoryEdit_card_header_bar">
          <div className="CatergoryEdit_card_header_bar_text">Article Edit</div>
        </div>
        <div
        style={{
          display:'flex', 
          flexDirection:'row', 
          justifyContent:'flex-end', 
          marginRight:'30px'
        }}
        > 
          <Button
          style={{
            backgroundColor: state.published ? 'rgba(18, 100, 100, 0.3)' : 'rgb(18, 100, 100)', 
            color:'white'
           
          }} onClick={((e) => {handlePublishedChange()})}
          >
            {state.published ?  'Published': 'Publish' }
           
          </Button>
        </div>

        <div className="ArticleEdit_card_content">
          <TextField
            label="Title"
            fullWidth
            style={{ marginTop: "10px" }}
            size="small"
            onChange={(e) => {
              handleTextChange(e, "title");
            }}
            value={state.title}
            onBlur={() => {
              handleDatabaseUpdate();
            }}
          />
          <TextField
            label="Introduction"
            fullWidth
            style={{ marginTop: "10px" }}
            size="small"
            multiline
            rows={4}
            onChange={(e) => {
              handleTextChange(e, "description");
            }}
            value={state.description}
            onBlur={() => {
              handleDatabaseUpdate();
            }}
          />

          <Badge
            style={{ width: "100%" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              
                <Fab
                  size="small"
                   onClick={(e) => {
                    handleContentCopy();
                  }} 
                  style={{
                    backgroundColor: "rgb(18, 100, 100)",
                    color: "white",
                  }}
                >
                  {" "}
                  <CopyAll />{" "}
                </Fab>
           
            }
          >
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              fullWidth
              className="ArticleEdit_HTML_Field"
              onBlur={() => {
                handleDatabaseUpdate();
              }}
            />
          </Badge>
          <div className="ArticleEdit_button">
            <Button
              style={{ color: "rgb(18, 100, 100)" }}
              onClick={() => {
                handleArticleDelete();
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Card>

      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{ maxWidth: "30%" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{ maxWidth: "30%" }}
      >
        <Alert onClose={handleErrorClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ArticleEdit;
