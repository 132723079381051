import { ArrowForwardIos, Logout, Menu, Send } from "@mui/icons-material";
import { IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import "./Header.css";
import logo from "../Images/tenacklogo.png";
import { sideBarData } from "./SideBarData";
import { logout } from "../firebase";
import { useNavigate } from "react-router-dom";

function Header() {
  const [sidebar, setSidebar] = React.useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const navigate = useNavigate();


  return (
    <>
      <div className="header_body">
        <div className="header_left">
          <div className="header_logo_sub_div">
            <img src={logo} alt="Tenack Logo" className="header_logo_img" />
          </div>
        </div>
        <div className="header_right">
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <IconButton>
                <Menu
                  style={{ color: "rgb(18, 100, 100)" }}
                  fontSize="large"
                  onClick={showSidebar}
                />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <div className="header_nav_menu_icon_button_div">
          <div>
            <IconButton>
              <ArrowForwardIos
                style={{ color: "white" }}
                fontSize="large"
                onClick={showSidebar}
              />
            </IconButton>
          </div>
        </div>

        <List className="header_sidebar_list"> 
            {sideBarData.map((item) => <>
            <div className="listItem_sidebar" onClick={(() => {navigate(item.path)})} key={item.title}> 
            <div className="listItem_sidebar" key={item.title}> 
            <div className="listItem_sidebar_left"> 
            {item.icon}
            </div>
            <div className="listItem_sidebar_right"> 
            {item.title}
            </div> 
            </div>
            </div>
            </>)}
            <div className="listItem_sidebar" onClick={(() => {
              
              window.open('https://employee-messages.web.app/home')
              
              })}> 
            <div className="listItem_sidebar"> 
            <div className="listItem_sidebar_left"> 
            <Send />
            </div>
            <div className="listItem_sidebar_right"> 
            SMS Messaging
            </div> 
            </div>
            </div>

            <div className="listItem_sidebar" onClick={(() => {logout()})}> 
            <div className="listItem_sidebar"> 
            <div className="listItem_sidebar_left"> 
            <Logout />
            </div>
            <div className="listItem_sidebar_right"> 
            Log Out
            </div> 
            </div>
            </div>


        </List> 

      </nav>
    </>
  );
}

export default Header;
