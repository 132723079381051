import React from "react";
import { Add } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  Fab,
  Snackbar,
  TextField,
} from "@mui/material";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";

function ArticleAdd(props) {
  const { catergory, filter } = props;

  ///// Handle Dialog Open and Close \\\\\
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  ///// Dialog Window \\\\\
  function DialogWindow() {
    const [state, setState] = React.useState({
      title: "",
      description: "",
    });

    function handleTextChange(e, field) {
      setState((state) => ({
        ...state,
        [field]: e.target.value,
      }));
    }

    async function handleCatergoryAdd() {
      await addDoc(collection(db, `${filter}-articles`, catergory.id, 'articles'), {
        title: state.title,
        description: state.description,
      })
        .then(async (r) => {
          console.log("success", r);

          await setState((state) => ({
            ...state,
            title: "",
            description: "",
            successOpen: true,
            successMessage: "Successfully added catergory",
          }));
        })
        .catch((r) => {
          setState((state) => ({
            ...state,
            errorOpen: false,
            errorMessage: "Failed to add catergory",
          }));
        });
    }

    const [vertical, setVertical] = React.useState("bottom");
    const [horizontal, setHorizontal] = React.useState("right");

    const handleErrorClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setState((state) => ({
        ...state,
        errorOpen: false,
        errorMessage: "",
        successMessage: "",
        successOpen: false,
      }));
    };

    return (
      <>
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
          <div className="page_title_bar">
            <div className="page_title">New Article</div>
          </div>
          <div className="GroupVArticleAdd_textFields">
            <TextField
              label="Title"
              size="small"
              fullWidth
              value={state.title}
              onChange={(e) => {
                handleTextChange(e, "title");
              }}
            />
            <TextField
              label="Quick Introduction"
              size="small"
              fullWidth
              multiline
              rows={5}
              value={state.description}
              onChange={(e) => {
                handleTextChange(e, "description");
              }}
            />
          </div>
          <div className="GroupVArticleAdd_buttonSection">
            <Button
              style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }}
              onClick={() => {
                handleCatergoryAdd();
              }}
            >
              <Add /> Add
            </Button>
          </div>
        </Dialog>

        <Snackbar
          open={state.errorOpen}
          autoHideDuration={6000}
          onClose={handleErrorClose}
          anchorOrigin={{ vertical, horizontal }}
          style={{ maxWidth: "30%" }}
        >
          <Alert onClose={handleErrorClose} severity="error">
            {state.errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={state.successOpen}
          autoHideDuration={6000}
          onClose={handleErrorClose}
          anchorOrigin={{ vertical, horizontal }}
          style={{ maxWidth: "30%" }}
        >
          <Alert onClose={handleErrorClose} severity="success">
            {state.successMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }

  ///// What will display when dialog window is closed, usally a button \\\\\
  return (
    <>
      <div className="spaces__card__edit__button__icon_bar">
        <Button
          style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }}
          className="property__dashboard__edit__button__icon"
          onClick={handleClickOpen}
        >
          <Add />
          Add Article
        </Button>
        <DialogWindow open={open} onClose={handleClose} />
      </div>
    </>
  );
}

export default ArticleAdd;
