import { FileOpen, Home } from "@mui/icons-material";

export const sideBarData = [
    {
        title: 'Support Documents', 
        icon: <FileOpen />, 
        path: '/support_documents'
    },
    {
        title: 'Home', 
        icon: <Home />, 
        path: '/dashboard'
    }
]