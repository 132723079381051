import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Login Screen/Login.js";
import Dashboard from "./Dashboard/Dashboard";
import SupportPage from "./Support Docs/SupportPage";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route index element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/support_documents" element={<SupportPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
